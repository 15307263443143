<template>
    
    <page page="gastenboek" theme="light" type="page">

        <div class="container">
            <div class="row section">
                <div class="col-md-5">
                    <p>
                        Wij zijn Evert en Ria Pruissen. 
                        Wij wonen samen met onze dieren aan de rand van het dorp Dussen op een erf, waar rust en activiteit zich prima mengen. 
                        Wij zijn in 1997 gestart met een meubelstoffeerderij in de huidige B&amp;B De Oude Stoffeerderij. 
                        Sinds een aantal jaren hebben we een sfeervol bedrijfspand gebouwd op het erf en kwam de oude werkplaats leeg te staan. 
                        Toen werd besloten om de Bed &amp; Breakfast te starten en de naam die we gekozen hebben, spreekt voor zich.
                    </p>
                </div>
                <div class="col-md-4">
                    <p>
                        Wij hebben de B&amp;B ingericht met her-gestoffeerde meubelen, kussens, voetenbanken en hoofdborden van de bedden om er een persoonlijke touch aan te geven en als u interesse heeft in deze meubelen. Alles is te koop!
                    </p>
                    <p>
                        Als we aan het werk zijn in de meubelstoffeerderij, bent u van harte welkom om een kijkje te komen nemen en het ambacht te aanschouwen.
                    </p>
                </div>
                <div class="col-md-3">
                    <img src="/static/img/Digital-Award-TRA-2022.png" alt="Booking.com award - De Oude Stoffeerderij" style="max-width: 250px; border-radius: 5px;">
                </div>
            </div>
        </div>

        <div class="image--block pos-left">
            <div class="image--block-content col-lg-6 col-md-4">
                <background-image image="accomodatie/small/pruissen.jpg"></background-image>
            </div>
            <div class="container">
                
                <div class="row section">
                    <div class="col-lg-5 col-lg-offset-7 col-md-7 col-md-offset-5">
                        <div class="review">
                            <p>
                                “Mooi rustig plekje, vrijstaand knus verzorgd huisje met eigen tuintje met overkapping. 
                                Geheel omheind. 3 kanten gebouw ( maar voelt super vrij) en 1 kan heg met daarin een laag gaasafrastering. We hebben even een stoel weggezet tegen het kleine stukje heg wat wat kaler was om ontsnapping te verhinderderen. Maar Ria en Evert houden er erg rekening mee dat ze hun eigen lieve hond Noa bij hen houden. 
                                Erg fijn! Dus ook voor hondjes die niet van vreemde honden houden aanrader! 
                                Het huis is schoon en verzorgd. Met super fijn bed, fijne keuken en badkamer en zelfs op afstandbestuurbaar dakraam . Heerlijk ontbijt! Zeer leuk en knus ingericht met eigen meubels erg leuk! 
                                Met ons bootvaardje nog tegoed, vanwege helaas het slechte weer. Maar wel erg aardig dat ze aangeven, dit later nog te mogen inhalen. 
                                Zeker aanrader, wij komen zeker terug!!”
                            </p>
                            <div class="author">
                                <span class="name">Anoniem</span>
                                <span class="location">Bergen op zoom, Noord-Brabant</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="image--block pos-right">
            <div class="image--block-content col-lg-6 col-md-4">
                <background-image image="accomodatie/small/sloot.jpg"></background-image>
            </div>
            <div class="container">
                
                <div class="row section">
                    <div class="col-lg-5 col-md-7">
                        <div class="review">
                            <p>
                                “De kinderen hielden van de plek omringd door boerderijdieren. De gastheren zijn attent en laten je thuis voelen.”
                            </p>
                            <div class="author">
                                <span class="name">Julien</span>
                                <span class="location">Lyon, Frankrijk</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="image--block pos-left">
            <div class="image--block-content col-lg-6 col-md-4">
                <background-image image="accomodatie/small/de-zaak.jpg"></background-image>
            </div>
            <div class="container">
                
                <div class="row section">
                    <div class="col-lg-5 col-lg-offset-7 col-md-7 col-md-offset-5">
                        <div class="review">
                            <p>
                                “Lieflijk huisje met een plattelands sfeertje en vriendelijke gastheer en dame.  
                                Zeker een aanrader als je even uit de drukte van het stadsleven wilt maar toch wilt genieten van de Nederlandse luxe en de rust van een pittoresk dorpje. Gelegen aan de rand van Dussen kun je hier heerlijk wandelen langs het kanaal. Je bent vanaf hier binnen 10 minuten bij de Biesbos waar het ongerepte natuur gevoel wederkeert.
                                Prijskwaliteit verhouding is uitstekend, mooi appartement en een heerlijk verzorgd ontbijt!”
                            </p>
                            <div class="author">
                                <span class="name">Tim de Laat</span>
                                <span class="location">Tilburg, Noord-Brabant</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="image--block pos-right">
            <div class="image--block-content col-lg-6 col-md-4">
                <background-image image="accomodatie/small/decoratie-serre.jpg"></background-image>
            </div>
            <div class="container">
                
                <div class="row section">
                    <div class="col-lg-5 col-md-7">
                        <div class="review">
                            <p>
                                “Een mooie moderne b&amp;b in een landelijk dorpje! Heerlijk wakker worden met een groot stuk natuur in de achtertuin. Van alle gemakken voorzien, inclusief een uitgebreide keuken. Bedden slapen goed wat natuurlijk niet geheel onbelangrijk is. 
                                De service is top, ontbijtje is veel maar wel heerlijk bereidt! Zeker een aanrader, ga hier zelf ook nog wel naar terug.”
                            </p>
                            <div class="author">
                                <span class="name">Caithlyn Schneider</span>
                                <span class="location">Geertruidenberg, Noord-Brabant</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="image--block pos-left">
            <div class="image--block-content col-lg-6 col-md-4">
                <background-image image="accomodatie/small/druiven.jpg"></background-image>
            </div>
            <div class="container">
                
                <div class="row section">
                    <div class="col-lg-5 col-lg-offset-7 col-md-7 col-md-offset-5">
                        <div class="review">
                            <p>
                                “Ik heb als verrassing voor mijn zus haar verjaardag deze B&amp;B geboekt. We werden heel warm en hartelijk ontvangen door een hele vriendelijke gastheer en gastvrouw. De B&amp;B is erg knus en van alle faciliteiten voor zien, ‘s ochtends kregen we een heerlijk ontbijtje met verse broodjes, een gekookt eitje, koffie en thee, etc. Kortom, een hele leuke B&amp;B in een mooie natuurrijke en groene omgeving!”
                            </p>
                            <div class="author">
                                <span class="name">Jenne Bras</span>
                                <span class="location">Breda, Noord-Brabant</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </page>

</template>

<script>
import Page from '../modules/AppPage.vue';
export default {
    metaInfo: {
        title: 'Ons gastenboek',
        meta: [
            { vmid: 'description', name: 'description', content: 'Om u een impressie te geven wat onze accomodatie te bieden hebben we een gastenboek, waarin de vorige bezoekers een bericht hebben achtergelaten. Zo leest u over de accomodatie zelf maar ook de omgeving waarin het zich bevindt.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/gastenboek'}
        ]
    },
    components: {
        Page
    }
}
</script>
